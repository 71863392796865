import { Logout } from '@mui/icons-material';
import React from 'react';

const Dashboard = React.lazy(() => import('./views/dashboard/Dashboard'));
const Colors = React.lazy(() => import('./views/theme/colors/Colors'));
const Typography = React.lazy(() => import('./views/theme/typography/Typography'));

// Base
const Customer = React.lazy(() => import('./views/base/customer/Customer'));
const Freelancer = React.lazy(() => import('./views/base/freelancer/Freelancer'));
const Businessman = React.lazy(() => import('./views/base/businessman/Businessman'));
const Category = React.lazy(() => import('./views/base/category/Category'));
const Services = React.lazy(() => import('./views/base/serivces/Services')); 
const Advertisement = React.lazy(() => import('./views/base/advertisement/Advertisement'));
const Offer = React.lazy(() => import('./views/base/offer/Offer'));
const Bizsales = React.lazy(() => import('./views/base/bizsales/Bizsales'));
const Subscription = React.lazy(() => import('./views/base/subscription/Subscription'));


const Notification = React.lazy(() => import('./views/base/notification/Notification'));
const Spinners = React.lazy(() => import('./views/base/spinners/Spinners'));
const Tabs = React.lazy(() => import('./views/base/tabs/Tabs'));
const Tables = React.lazy(() => import('./views/base/tables/Tables'));
const Tooltips = React.lazy(() => import('./views/base/tooltips/Tooltips'));

// Buttons
const Buttons = React.lazy(() => import('./views/buttons/buttons/Buttons'));
const ButtonGroups = React.lazy(() => import('./views/buttons/button-groups/ButtonGroups'));
const Dropdowns = React.lazy(() => import('./views/buttons/dropdowns/Dropdowns'));

// Forms
const ChecksRadios = React.lazy(() => import('./views/forms/checks-radios/ChecksRadios'));
const FloatingLabels = React.lazy(() => import('./views/forms/floating-labels/FloatingLabels'));
const FormControl = React.lazy(() => import('./views/forms/form-control/FormControl'));
const InputGroup = React.lazy(() => import('./views/forms/input-group/InputGroup'));
const Layout = React.lazy(() => import('./views/forms/layout/Layout'));
const Range = React.lazy(() => import('./views/forms/range/Range'));
const Select = React.lazy(() => import('./views/forms/select/Select'));
const Validation = React.lazy(() => import('./views/forms/validation/Validation'));

const Charts = React.lazy(() => import('./views/charts/Charts'));

// Icons
const CoreUIIcons = React.lazy(() => import('./views/icons/coreui-icons/CoreUIIcons'));
const Flags = React.lazy(() => import('./views/icons/flags/Flags'));
const Brands = React.lazy(() => import('./views/icons/brands/Brands'));

// Notifications
const Alerts = React.lazy(() => import('./views/notifications/alerts/Alerts'));
const Badges = React.lazy(() => import('./views/notifications/badges/Badges'));
const Modals = React.lazy(() => import('./views/notifications/modals/Modals'));
const Toasts = React.lazy(() => import('./views/notifications/toasts/Toasts'));

const Widgets = React.lazy(() => import('./views/widgets/Widgets'));

const routes = [
  { path: '/dashboard', name: 'Dashboard', element: Dashboard },
  { path: '/admin/customer', name: 'Customer', element: Customer },
  { path: '/admin/freelancer', name: 'Freelancer', element: Freelancer },
  { path: '/admin/businessman', name: 'Businessman', element: Businessman },
  { path: '/admin/category', name: 'Category', element: Category },
  { path: '/admin/services', name: 'Services', element: Services }, // Ensure name consistency
  { path: '/admin/advertise', name: 'Advertisement', element: Advertisement },
  { path: '/admin/offer', name: 'Today Offer', element: Offer },
  { path: '/admin/sales', name: 'Bizsales', element: Bizsales },
  { path: '/admin/subscription', name: 'Subscription', element: Subscription },
  { path: '/admin/notification', name: 'Notification', element: Notification },
  
  
];

export default routes;
