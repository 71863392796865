import React from 'react';
import './style.css';
import {  Grid,Container  } from '@mui/material';
import NavBar from '../navbar';
import Footer from '../footer';





function Terms(){

    return(
        <>
        <NavBar/>
        <Container >
            <Grid mt={10} xs={10}>
            <h2>Terms Of Use For Information Dissemination</h2>
    <p>This document is an electronic record in terms of the amended Information Technology Act, 2000 and rules and regulation made thereunder. This electronic record is generated by a computer system and does not require any physical or digital signatures. This document is published in accordance with the provisions of Rule 3 (1) of the Information Technology (Intermediaries Guidelines) Rules, 2011 that require publishing the Terms for access or usage of Qik call’s service via Qik CallPortal. This document meets the stipulations and conditions mentioned in Section 65B (2) of the Indian Evidence Act, 1872.</p>
    
    <h2>YOUR ACCEPTANCE OF THIS AGREEMENT:</h2>
    <p>This is an agreement between you ("you" or "your") and Qik CallLimited, a company incorporated under the Companies Act 1956 with its registered office at Building M, 501-B, Palm Court Complex, Besides Goregaon Sports Club, New Link Road, Malad (W), Mumbai 400 064 ("Qik call" "we," or "our") that governs your use of the search services offered by Qik Callthrough its website <a href="http://www.qikcalll.com">www.qikcalll.com</a> ("Website"), telephone search, SMS, WAP or any other medium using which Qik Callmay provide the search services (collectively "Platforms"). When you access or use any of the Platforms you agree to be bound by these Terms and Conditions ("Terms").</p>
    
    <h2>CHANGES:</h2>
    <p>We may periodically change the Terms and the Site without notice, and you are responsible for checking these Terms periodically for revisions. All amended Terms become effective upon our posting to the Site, and any use of the site after such revisions have been posted signifies your consent to the changes.</p>
    
    <h2>HOW YOU MAY USE OUR MATERIALS:</h2>
    <p>We use a diverse range of information, text, photographs, designs, graphics, images, sound and video recordings, animation, content, advertisement and other materials and effects (collectively "Materials") for the search services on the Platforms. We provide the Material through the Platforms FOR YOUR PERSONAL AND NON-COMMERCIAL USE ONLY.</p>
    <p>While every attempt has been made to ascertain the authenticity of the Platforms content, Qik Callis not liable for any kind of damages, losses or action arising directly or indirectly, due to access and/or use of the content in the Platforms including but not limited to decisions based on the content in the Platforms which results in any loss of data, revenue, profits, property, infection by viruses etc.</p>
    <p>Accordingly, you may view, use, copy, and distribute the Materials found on the Platforms for internal, non-commercial, informational purposes only. You are prohibited from data mining, scraping, crawling, or using any process or processes that send automated queries to Qik call. You may not use the Platforms or any of them to compile a collection of listings, including a competing listing product or service. You may not use the Platforms or any Materials for any unsolicited commercial e-mail. Except as authorized in this paragraph, you are not being granted a license under any copyright, trademark, patent or other intellectual property right in the Materials or the products, services, processes or technology described therein. All such rights are retained by Qik call, its subsidiaries, parent companies, and/or any third party owner of such rights.</p>
    
    <h2>HOW YOU MAY USE OUR MARKS:</h2>
    <p>The Qik Callcompany names and logos and all related products and service names, design marks and slogans are trademarks and service marks owned by and used under license from Qik Callor its wholly owned subsidiaries. All other trademarks and service marks herein are the property of their respective owners. All copies that you make of the Materials on any of the Platforms must bear any copyright, trademark or other proprietary notice located on the respective Platforms that pertains to the material being copied. You are not authorized to use any Qik Callname or mark in any advertising, publicity or in any other commercial manner without the prior written consent of Qik call. Requests for authorization should be made to <a href="mailto:intproperty@qikcalll.com">intproperty@qikcalll.com</a></p>
    
    <h2>HOW WE MAY USE INFORMATION YOU PROVIDE TO US:</h2>
    <p>Do not send us any confidential or proprietary information. Except for any personally identifiable information that we agree to keep confidential as provided in our Privacy Policy, any material, including, but not limited to any feedback, data, answers, questions, comments, suggestions, ideas or the like, which you send to us will be treated as being non-confidential and nonproprietary. We assume no obligation to protect confidential or proprietary information (other than personally identifiable information) from disclosure and will be free to reproduce, use, and distribute the information to others without restriction. We will also be free to use any ideas, concepts, know-how or techniques contained in information that you send us for any purpose whatsoever including but not limited to developing, manufacturing and marketing products and services incorporating such information.</p>
    
    <h2>REVIEWS, RATINGS & COMMENTS BY USERS:</h2>
    <p>Since, Qik Callprovides information directory services through various mediums (SMS, WAP, E-Mail, Website, APP and voice or phone), your ("Users") use any of the aforementioned medium to post Reviews, Ratings and Comments about the Qik Callservices and also about the Advertiser's listed at Qik Callis subject to additional terms and conditions as mentioned herein.</p>
    <p>You are solely responsible for the content of any transmissions you make to the Site or any transmissions you make to any mediums offered by Qik Calland any materials you add to the Site or add to any mediums offered by Qik call, including but not limited to transmissions like your Reviews, Ratings & Comments posted by you (the "Communications"). Qik Calldoes not endorse or accept any of your Communication as representative of their (Qik call) views. By transmitting any public Communication to the Site, you grant Qik Callan irrevocable, non-exclusive, worldwide, perpetual, unrestricted, royalty-free license (with the right to sublicense) to use, reproduce, distribute, publicly display, publicly perform, adapt, modify, edit, create derivative works from, incorporate into one or more compilations and reproduce and distribute such compilations, and otherwise exploit such Communications, in all Platforms now known or later developed.</p>
    <p>You confirm and warrant that you have the right to grant these rights to Qik call. You hereby waive and grant to Qik Callall rights including intellectual property rights and also "moral rights" in your Communications, posted at Qik Callthrough any of mediums of Qik call. Qik Callis free to use all your Communications as per its requirements from time to time. You represent and warrant that you own or otherwise control all of the rights to the content that you post as Review, Rating or Comments; that the content is accurate; that use of the content you supply does not violate these Terms and will not cause injury to any person or entity. For removal of doubts it is clarified that, the reference to Communications would also mean to include the reviews, ratings and comments posted by your Friend's tagged by you. Also Qik Callreserves the right to mask or unmask your identity in respect of your Reviews, Ratings & Comments posted by you.</p>
    <p>Qik Callhas the right, but not the obligation to monitor and edit or remove any content posted by you as Review, Rating or Comments. Qik Callcannot review all Communications made on and through any of the mediums of Qik call. However, Qik Callreserves the right, but has no obligation, to monitor and edit, modify or delete any Communications (or portions thereof) which Qik Callin its sole discretion deems inappropriate, offensive or contrary to any Qik Callpolicy, or that violate these terms:</p>
    <p>Qik Callreserves the right not to upload or distribute to, or otherwise publish through the Site or Forums any Communication which</p>
    <ul>
        <li>is obscene, indecent, pornographic, profane, sexually explicit, threatening, or abusive;</li>
        <li>constitutes or contains false or misleading indications of origin or statements of fact;</li>
        <li>slanders, libels, defames, disparages, or otherwise violates the legal rights of any third party;</li>
        <li>causes injury of any kind to any person or entity;</li>
        <li>infringes or violates the intellectual property rights (including copyright, patent and trademark rights), contract rights, trade secrets, privacy or publicity rights or any other rights of any third party;</li>
        <li>violates any applicable laws, rules, or regulations;</li>
        <li>contains software viruses or any other malicious code designed to interrupt, destroy or limit the functionality of any computer software or hardware or telecommunications equipment;</li>
        <li>impersonates another person or entity, or that collects or uses any information about Site visitors.</li>
    </ul>
    <p>It is also clarified that, if there are any issues or claims due to your Reviews, Ratings and Comments, then Qik Callreserves right to take appropriate legal action against you. Further, you shall indemnify and protect Qik Callagainst such claims or damages or any issues, due to your posting of such Reviews, Ratings and Comments Qik Calltakes no responsibility and assumes no liability for any content posted by you or any third party on Qik Callsite or on any mediums of Qik call.</p>
    <p>ADVERTISING:</p>
    <p>Qik Callreserves the right to refuse, reject, omit, remove or suspend any advertisement in its sole discretion for any reason. However, Qik Callwill inform the advertiser in such case. Qik Callis not liable for any damages, loss or expense of any sort incurred by you as a result of any error or failure or delay in the listing of your advertisement.</p>
    <p>If you observe any kind of misuse or abuse of our Services or any breach of these terms and conditions, please report the same to <a href="mailto:abuse@qikcalll.com">abuse@qikcalll.com</a>.</p>
    
    <p><em>For further information please feel free to contact us at:</em></p>
    <address>
        Qik Call Limited,<br/>
        Building M, 501-B, Palm Court Complex,<br/>
        Besides Goregaon Sports Club,<br/>
        New Link Road, Malad (W),<br/>
        Mumbai - 400064.<br/>
        Phone: +91-22-28884060<br/>
        Email: <a href="mailto:info@qikcalll.com">info@qikcalll.com</a>
    </address>
            </Grid>
        </Container>

        <Footer/>
        </>
    )
}
 
export default Terms